function $parcel$interopDefault(a) {
    return a && a.__esModule ? a.default : a;
}
function $parcel$defineInteropFlag(a) {
    Object.defineProperty(a, '__esModule', {
        value: true,
        configurable: true
    });
}
function $parcel$export(e, n, v, s) {
    Object.defineProperty(e, n, {
        get: v,
        set: s,
        enumerable: true,
        configurable: true
    });
}
var $parcel$global = globalThis;
var parcelRequire = $parcel$global["parcelRequire49d7"];
var parcelRegister = parcelRequire.register;
parcelRegister("1ExYN", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "default", ()=>$d823a751937e7046$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "PANEL_ID", ()=>(parcelRequire("7Hq8b")).PANEL_ID);
    $parcel$export(module.exports, "SIDEBAR_WIDTH", ()=>(parcelRequire("7Hq8b")).SIDEBAR_WIDTH);
    $parcel$export(module.exports, "useSetProjectListRightPanelState", ()=>(parcelRequire("dW6Zn")).useSetProjectListRightPanelState);
    var $gmMlk;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $7CMCs;
    var $hsmeR;
    var $8zOmE;
    var $2NMNM;
    var $7Hq8b;
    var $1sO0m;
    var $7Hq8b;
    var $dW6Zn;
    const $d823a751937e7046$var$JiraProjectsTemplateListRightPanel = ({ queries: { templatesQueryRef: templatesQueryRef } })=>{
        const { openRightSidebar: openRightSidebar, closeRightSidebar: closeRightSidebar } = (0, (0, parcelRequire("hsmeR")).useRightSidebarController)();
        const rootQueryRef = (0, $8Uumt.usePreloadedQuery)((0, (0, parcelRequire("gmMlk")).default), templatesQueryRef);
        const { jira: jira } = rootQueryRef;
        const isAnonymous = templatesQueryRef?.variables.isAnonymous;
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        (0, $5uXOq.useEffect)(()=>{
            if (isAnonymous || !jira) return;
            if (!(typeof jira?.sidebarExperimentTemplates?.totalCount === 'number') || !(jira?.sidebarExperimentTemplates?.totalCount > 0) || !jira?.userPreferences?.projectListRightPanelState || !jira?.canCreateProject) {
                (0, (0, parcelRequire("8zOmE")).fireTrackAnalytics)(createAnalyticsEvent({}), 'sidebarEligibility failed');
                return;
            }
            if (jira?.userPreferences?.projectListRightPanelState === 'OPEN') openRightSidebar((0, (0, parcelRequire("7Hq8b")).PANEL_ID), (0, (0, parcelRequire("7Hq8b")).SIDEBAR_WIDTH), false, true);
            if (jira?.userPreferences?.projectListRightPanelState === 'CLOSED') closeRightSidebar((0, (0, parcelRequire("7Hq8b")).PANEL_ID));
        }, [
            openRightSidebar,
            closeRightSidebar,
            createAnalyticsEvent,
            isAnonymous,
            jira?.sidebarExperimentTemplates?.totalCount,
            jira?.userPreferences?.projectListRightPanelState,
            jira?.canCreateProject,
            jira
        ]);
        const rightSidebarCurrentState = (0, (0, parcelRequire("hsmeR")).useActiveRightSidebarState)();
        const [localPanelId, setLocalPanelId] = (0, $5uXOq.useState)();
        const prevPanelIdRef = (0, $5uXOq.useRef)();
        const delayRef = (0, $5uXOq.useRef)(undefined);
        const panelId = rightSidebarCurrentState?.panelId;
        (0, $5uXOq.useEffect)(()=>{
            clearTimeout(delayRef.current);
            if (prevPanelIdRef.current === (0, (0, parcelRequire("7Hq8b")).PANEL_ID)) {
                if (rightSidebarCurrentState && panelId !== undefined && !rightSidebarCurrentState.isCollapsed && !rightSidebarCurrentState.isMinimised) setLocalPanelId(panelId);
                else delayRef.current = setTimeout(()=>{
                    setLocalPanelId(panelId);
                }, 310);
            } else setLocalPanelId(panelId);
            prevPanelIdRef.current = panelId;
            return ()=>clearTimeout(delayRef.current);
        }, [
            panelId,
            rightSidebarCurrentState
        ]);
        (0, $5uXOq.useEffect)(()=>()=>{
                closeRightSidebar((0, (0, parcelRequire("7Hq8b")).PANEL_ID));
            }, [
            closeRightSidebar
        ]);
        if (localPanelId !== (0, (0, parcelRequire("7Hq8b")).PANEL_ID)) return null;
        if (isAnonymous) return null;
        if (!rootQueryRef?.jira) throw new Error('Failed to load sidebar content');
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1sO0m")).default), {
            rootQueryRef: rootQueryRef.jira
        });
    };
    const $d823a751937e7046$var$JiraProjectsTemplateListRightPanelContainer = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7CMCs")).JSErrorBoundary), {
            fallback: "unmount",
            id: "right-sidebar-layout-component",
            packageName: "jiraGrowthRecommendationsInProjectList"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($d823a751937e7046$var$JiraProjectsTemplateListRightPanel, props));
    var $d823a751937e7046$export$2e2bcd8739ae039 = $d823a751937e7046$var$JiraProjectsTemplateListRightPanelContainer;
});
parcelRegister("gmMlk", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bfa8bf4ed8631db9$export$2e2bcd8739ae039);
    var $eiowN = parcelRequire("eiowN");
    const $bfa8bf4ed8631db9$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "isAnonymous"
            }
        ], v1 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        }, v2 = [
            v1
        ], v3 = {
            "kind": "ScalarField",
            "name": "projectListRightPanelState"
        }, v4 = [
            v1,
            {
                "kind": "Literal",
                "name": "experimentKey",
                "value": "sidebarExperiment"
            }
        ], v5 = {
            "alias": "sidebarExperimentTemplates",
            "args": v4,
            "concreteType": "JiraProjectListViewTemplateConnection",
            "kind": "LinkedField",
            "name": "projectListViewTemplates",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "name": "totalCount"
                }
            ]
        }, v6 = {
            "alias": "canCreateProject",
            "args": [
                v1,
                {
                    "kind": "Literal",
                    "name": "type",
                    "value": "CREATE_PROJECT"
                }
            ],
            "kind": "ScalarField",
            "name": "canPerform"
        };
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
                "selections": [
                    {
                        "condition": "isAnonymous",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                            {
                                "concreteType": "JiraQuery",
                                "kind": "LinkedField",
                                "name": "jira",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v2,
                                        "concreteType": "JiraUserPreferences",
                                        "kind": "LinkedField",
                                        "name": "userPreferences",
                                        "plural": false,
                                        "selections": [
                                            v3
                                        ]
                                    },
                                    v5,
                                    v6,
                                    {
                                        "kind": "FragmentSpread",
                                        "name": "ui_growthRecommendationsInProjectList_RecommendationsInProjectList"
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Query"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
                "selections": [
                    {
                        "condition": "isAnonymous",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                            {
                                "concreteType": "JiraQuery",
                                "kind": "LinkedField",
                                "name": "jira",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v2,
                                        "concreteType": "JiraUserPreferences",
                                        "kind": "LinkedField",
                                        "name": "userPreferences",
                                        "plural": false,
                                        "selections": [
                                            v3,
                                            {
                                                "kind": "ClientExtension",
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "__id"
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    v5,
                                    v6,
                                    {
                                        "args": v4,
                                        "concreteType": "JiraProjectListViewTemplateConnection",
                                        "kind": "LinkedField",
                                        "name": "projectListViewTemplates",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "concreteType": "JiraProjectListViewTemplateItem",
                                                "kind": "LinkedField",
                                                "name": "nodes",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "key"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "templateType"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "productKey"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "isProductLicensed"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "title"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "shortDescription"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "isPremiumOnly"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "canCreate"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "recommendationSessionId"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "iconUrl"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "iconDarkUrl"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "description"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "previewUrl"
                                                    },
                                                    {
                                                        "kind": "ScalarField",
                                                        "name": "previewDarkUrl"
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "8bd2c50d7613ef27c0c7f5d9ff9b9f059c7e8c6add7f7091c71b35680d18da7b",
                "metadata": {},
                "name": "src_growthRecommendationsInProjectList_RecommendationsInProjectListQuery",
                "operationKind": "query",
                "text": null
            }
        };
    }();
    $bfa8bf4ed8631db9$var$node.hash = "1d5593aeca46929a56364eb8ee925a79";
    (0, $eiowN.PreloadableQueryRegistry).set($bfa8bf4ed8631db9$var$node.params.id, $bfa8bf4ed8631db9$var$node);
    var $bfa8bf4ed8631db9$export$2e2bcd8739ae039 = $bfa8bf4ed8631db9$var$node;
});
parcelRegister("7Hq8b", function(module, exports) {
    $parcel$export(module.exports, "SIDEBAR_WIDTH", ()=>$995a11da0cd37d3a$export$780035d9fe48eb32);
    $parcel$export(module.exports, "PANEL_ID", ()=>$995a11da0cd37d3a$export$e1d562d81d0e502d);
    $parcel$export(module.exports, "PROJECT_CREATE_SOURCE", ()=>$995a11da0cd37d3a$export$280536374592bda3);
    $parcel$export(module.exports, "PROJECT_TYPE_TARGETS_MAP", ()=>$995a11da0cd37d3a$export$990484f4e8c06c90);
    $parcel$export(module.exports, "getProductTypeCcpReferenceIdMapForEnv", ()=>$995a11da0cd37d3a$export$29629758934356a6);
    var $941eD = parcelRequire("941eD");
    var $iDfVE;
    const $995a11da0cd37d3a$export$780035d9fe48eb32 = 365;
    const $995a11da0cd37d3a$export$e1d562d81d0e502d = 'recommendationsInProjectList';
    const $995a11da0cd37d3a$export$675d6d49067be942 = 'recommendationsInProjectListHoverAnchor';
    const $995a11da0cd37d3a$export$280536374592bda3 = 'recommendationsInProjectList';
    const $995a11da0cd37d3a$export$990484f4e8c06c90 = {
        BUSINESS: (0, $941eD.Targets).JIRA_CORE,
        SOFTWARE: (0, $941eD.Targets).JIRA_SOFTWARE,
        SERVICE_DESK: (0, $941eD.Targets).JIRA_SERVICE_DESK,
        PRODUCT_DISCOVERY: (0, $941eD.Targets).JIRA_PRODUCT_DISCOVERY
    };
    const $995a11da0cd37d3a$export$29629758934356a6 = (environment)=>{
        const env = environment === 'prod' ? 'prod' : 'staging';
        return {
            BUSINESS: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_work_management_cloud,
            SOFTWARE: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_software_cloud,
            SERVICE_DESK: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_service_management_cloud,
            PRODUCT_DISCOVERY: (0, (0, parcelRequire("iDfVE")).FIRST_PARTY_CCP_PRODUCT_IDS)[env].jira_product_discovery
        };
    };
});
parcelRegister("1sO0m", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9eda1dd315a515c4$export$2e2bcd8739ae039);
    var $imeZ2;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $fVnbM;
    var $l1N8i;
    var $lLLkM;
    var $35XSW;
    var $4uxPN;
    var $1I7E8;
    var $dC5iT;
    var $f5TWP;
    var $luBm6;
    var $8CUq3;
    var $7CMCs;
    var $koVbs;
    var $iqLcb;
    var $iXVV6;
    var $5oFIy;
    var $8zOmE;
    var $2NMNM;
    var $bKCrm;
    var $2tT7R;
    var $cmVQ7;
    var $7Hq8b;
    var $cFkGL;
    var $dW6Zn;
    var $hIu9K;
    var $aV0Up;
    var $8YXli;
    const $9eda1dd315a515c4$var$RecommendationsInProjectList = ({ rootQueryRef: rootQueryRef })=>{
        const [templatePreviewKey, setTemplatePreviewKey] = (0, $5uXOq.useState)(null);
        const environment = (0, (0, parcelRequire("2tT7R")).useEnvironment)();
        const { fireViewedUIEvent: fireViewedUIEvent } = (0, (0, parcelRequire("cmVQ7")).useFireCrossFlowAnalyticsEvents)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { methods: { open: openProjectCreate } } = (0, (0, parcelRequire("bKCrm")).useProjectCreateDrawer)();
        const buttonEvent = createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'button'
        });
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { projectListViewTemplates: projectListViewTemplates, userPreferences: userPreferences } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("imeZ2")).default), rootQueryRef);
        const setProjectListRightPanelState = (0, (0, parcelRequire("dW6Zn")).useSetProjectListRightPanelState)(userPreferences?.__id);
        const onCloseClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(buttonEvent, 'closeButton');
            setProjectListRightPanelState('CLOSED');
        }, [
            buttonEvent,
            setProjectListRightPanelState
        ]);
        const templates = (0, $5uXOq.useMemo)(()=>projectListViewTemplates?.nodes?.filter((template)=>!!template) ?? [], [
            projectListViewTemplates?.nodes
        ]);
        const templatePreviewRef = (0, $5uXOq.useMemo)(()=>templates.find((template)=>template.key === templatePreviewKey), [
            templatePreviewKey,
            templates
        ]);
        const recommendedProductIds = (0, $5uXOq.useMemo)(()=>Array.from(templates.reduce((acc, template)=>{
                const { isProductLicensed: isProductLicensed, productKey: productKey } = template;
                if (!isProductLicensed) acc.add((0, (0, parcelRequire("7Hq8b")).getProductTypeCcpReferenceIdMapForEnv)(environment)[productKey]);
                return acc;
            }, new Set())), [
            environment,
            templates
        ]);
        const onMoreTemplatesClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(buttonEvent, 'moreTemplatesButton');
            openProjectCreate({
                showExperienceSelection: false,
                source: (0, (0, parcelRequire("7Hq8b")).PROJECT_CREATE_SOURCE)
            });
        }, [
            buttonEvent,
            openProjectCreate
        ]);
        (0, $5uXOq.useEffect)(()=>{
            recommendedProductIds.length > 0 && fireViewedUIEvent({
                action: 'viewed',
                actionSubject: 'section',
                actionSubjectId: 'projectsDirectorySidebar'
            }, {
                recommendedProductIds: recommendedProductIds,
                source: 'projectsDirectorySidebarSection'
            });
        }, [
            fireViewedUIEvent,
            recommendedProductIds
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1I7E8")).default), {
            isOpen: true,
            placement: "left-start",
            offset: [
                0,
                0
            ],
            autoFocus: false,
            trigger: (triggerProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
                    ...triggerProps,
                    "aria-expanded": undefined
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    id: "jira-project-list-side-panel",
                    xcss: $9eda1dd315a515c4$var$SidebarContainerStyles
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
                    xcss: $9eda1dd315a515c4$var$ExperimentSidebarContentStyles
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
                    xcss: $9eda1dd315a515c4$var$TitleStyles,
                    alignBlock: "center",
                    spread: "space-between"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l1N8i")).default), {
                    size: "xxsmall",
                    as: "div"
                }, formatMessage((0, (0, parcelRequire("cFkGL")).messages).title)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    xcss: $9eda1dd315a515c4$var$ButtonBoxStyles
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    iconBefore: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault((0, parcelRequire("lLLkM"))))), {
                        label: ""
                    }),
                    "aria-label": formatMessage((0, (0, parcelRequire("cFkGL")).messages).closeButton),
                    appearance: "subtle",
                    onClick: onCloseClick
                }))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                    as: "p",
                    xcss: $9eda1dd315a515c4$var$SubtitleStyles
                }, formatMessage((0, (0, parcelRequire("cFkGL")).messages).subtitle))), templates.map((cardRef)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hIu9K")).TemplateCard), {
                        key: cardRef.key,
                        dataRef: cardRef,
                        recommendedProductIds: recommendedProductIds,
                        setTemplatePreviewKey: setTemplatePreviewKey
                    })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fVnbM")).default), {
                    appearance: "link",
                    spacing: "none",
                    label: formatMessage((0, (0, parcelRequire("cFkGL")).messages).moreTemplatesLink),
                    onClick: ()=>onMoreTemplatesClick()
                }, formatMessage((0, (0, parcelRequire("cFkGL")).messages).moreTemplatesLink)))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireScreenAnalytics), {
                    attributes: {
                        templates: templates.map(({ key: key })=>key),
                        templateTypes: templates.map(({ templateType: templateType })=>templateType)
                    }
                }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8YXli")).JiraProjectListSidePanelSkipLink), null))),
            content: ({ update: update })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7CMCs")).JSErrorBoundary), {
                    id: "template-preview-wrapper",
                    packageName: "jiraGrowthRecommendationsInProjectList"
                }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("35XSW")).default), null, templatePreviewRef && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4uxPN")).default), {
                    duration: 500
                }, (fadeInProps)=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", fadeInProps, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aV0Up")).TemplatePreview), {
                        update: update,
                        previewRef: templatePreviewRef
                    })))))
        });
    };
    const $9eda1dd315a515c4$var$RecommendationsInProjectListContainer = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: "projectListTemplates",
            sourceType: (0, $4d5e0871c06cee03$export$9d2de6eebf7462b5)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($9eda1dd315a515c4$var$RecommendationsInProjectList, props));
    const $9eda1dd315a515c4$var$TitleStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        textTransform: 'uppercase',
        color: 'color.text.subtle',
        position: 'relative'
    });
    const $9eda1dd315a515c4$var$ButtonBoxStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        paddingInline: 'space.050'
    });
    const $9eda1dd315a515c4$var$SubtitleStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text.subtlest',
        font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        marginTop: 'space.0'
    });
    const $9eda1dd315a515c4$var$ExperimentSidebarContentStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: '100%',
        overflow: 'auto',
        width: `${(0, (0, parcelRequire("7Hq8b")).SIDEBAR_WIDTH)}px`,
        padding: 'space.300',
        backgroundColor: 'elevation.surface.sunken',
        boxSizing: 'border-box'
    });
    const $9eda1dd315a515c4$var$SidebarContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        height: `calc(100vh - ${(0, (0, parcelRequire("iqLcb")).UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT)} - ${(0, (0, parcelRequire("iqLcb")).UNSAFE_DO_NOT_USE_BANNER_HEIGHT)})`
    });
    var $9eda1dd315a515c4$export$2e2bcd8739ae039 = $9eda1dd315a515c4$var$RecommendationsInProjectListContainer;
});
parcelRegister("imeZ2", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$7a7db4f962dcd11b$export$2e2bcd8739ae039);
    const $7a7db4f962dcd11b$var$node = function() {
        var v0 = {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
        };
        return {
            "argumentDefinitions": [
                {
                    "kind": "RootArgument",
                    "name": "cloudId"
                }
            ],
            "kind": "Fragment",
            "name": "ui_growthRecommendationsInProjectList_RecommendationsInProjectList",
            "selections": [
                {
                    "args": [
                        v0,
                        {
                            "kind": "Literal",
                            "name": "experimentKey",
                            "value": "sidebarExperiment"
                        }
                    ],
                    "concreteType": "JiraProjectListViewTemplateConnection",
                    "kind": "LinkedField",
                    "name": "projectListViewTemplates",
                    "plural": false,
                    "selections": [
                        {
                            "concreteType": "JiraProjectListViewTemplateItem",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "RequiredField",
                                    "field": {
                                        "kind": "ScalarField",
                                        "name": "key"
                                    },
                                    "action": "THROW",
                                    "path": "projectListViewTemplates.nodes.key"
                                },
                                {
                                    "kind": "RequiredField",
                                    "field": {
                                        "kind": "ScalarField",
                                        "name": "templateType"
                                    },
                                    "action": "THROW",
                                    "path": "projectListViewTemplates.nodes.templateType"
                                },
                                {
                                    "kind": "RequiredField",
                                    "field": {
                                        "kind": "ScalarField",
                                        "name": "productKey"
                                    },
                                    "action": "THROW",
                                    "path": "projectListViewTemplates.nodes.productKey"
                                },
                                {
                                    "kind": "RequiredField",
                                    "field": {
                                        "kind": "ScalarField",
                                        "name": "isProductLicensed"
                                    },
                                    "action": "THROW",
                                    "path": "projectListViewTemplates.nodes.isProductLicensed"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "card_growthRecommendationsInProjectList_TemplateCard"
                                },
                                {
                                    "kind": "FragmentSpread",
                                    "name": "preview_growthRecommendationsInProjectList_TemplatePreview"
                                }
                            ]
                        }
                    ]
                },
                {
                    "args": [
                        v0
                    ],
                    "concreteType": "JiraUserPreferences",
                    "kind": "LinkedField",
                    "name": "userPreferences",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ClientExtension",
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "name": "__id"
                                }
                            ]
                        }
                    ]
                }
            ],
            "type": "JiraQuery"
        };
    }();
    $7a7db4f962dcd11b$var$node.hash = "117947c2155195c861516e1daa9cbd39";
    var $7a7db4f962dcd11b$export$2e2bcd8739ae039 = $7a7db4f962dcd11b$var$node;
});
const $4d5e0871c06cee03$export$f636b5b56f1d624a = 'Screen';
const $4d5e0871c06cee03$export$9d2de6eebf7462b5 = 'Drawer';
const $4d5e0871c06cee03$export$1171103ea9083ffa = 'Modal';
const $4d5e0871c06cee03$export$cae2783c9279926 = 'InlineDialog';
const $4d5e0871c06cee03$export$d1123261ca8831d2 = 'Dropdown';
const $4d5e0871c06cee03$export$238d30785c4bca84 = 'Banner';
const $4d5e0871c06cee03$export$a1562d08625f5d7e = 'UI';
const $4d5e0871c06cee03$export$947dac6c72b26f81 = 'TRACK';
const $4d5e0871c06cee03$export$f1d537d508d2945a = 'SCREEN';
const $4d5e0871c06cee03$export$12346a674644c156 = 'OPERATIONAL';
parcelRegister("cFkGL", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$e21678ead85b88c7$export$defe85febe8b728c);
    var $7VHMR;
    const $e21678ead85b88c7$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "growth-recommendations-in-project-list.title",
            "defaultMessage": "Templates"
        },
        subtitle: {
            "id": "growth-recommendations-in-project-list.subtitle",
            "defaultMessage": "Preview a template for your next project"
        },
        moreTemplatesLink: {
            "id": "growth-recommendations-in-project-list.more-templates-link",
            "defaultMessage": "More templates"
        },
        closeButton: {
            "id": "growth-recommendations-in-project-list.close-button",
            "defaultMessage": "Close"
        },
        tryLozenge: {
            "id": "growth-recommendations-in-project-list.try-lozenge",
            "defaultMessage": "Try"
        },
        premiumLozenge: {
            "id": "growth-recommendations-in-project-list.premium-lozenge",
            "defaultMessage": "Premium"
        },
        productKeyBusinessLabelSpork: {
            "id": "growth-recommendations-in-project-list.product-key-business-label-spork",
            "defaultMessage": "Get Jira to use this template"
        },
        productKeySoftwareLabelSpork: {
            "id": "growth-recommendations-in-project-list.product-key-software-label-spork",
            "defaultMessage": "Get Jira to use this template"
        },
        productKeyServiceDeskLabel: {
            "id": "growth-recommendations-in-project-list.product-key-service-desk-label",
            "defaultMessage": "Get Jira Service Management to use this template"
        },
        productKeyProductDiscoveryLabel: {
            "id": "growth-recommendations-in-project-list.product-key-product-discovery-label",
            "defaultMessage": "Get Jira Product Discovery to use this template"
        },
        premiumTemplateDiscoveryLabelSpork: {
            "id": "growth-recommendations-in-project-list.premium-template-discovery-label-spork",
            "defaultMessage": "Upgrade to Jira Premium to use this template"
        }
    });
});
parcelRegister("dW6Zn", function(module, exports) {
    $parcel$export(module.exports, "useSetProjectListRightPanelState", ()=>$0cab5e96cb0d59c5$export$f7872413c3847449);
    var $5pqbm;
    var $5uXOq = parcelRequire("5uXOq");
    var $8Uumt = parcelRequire("8Uumt");
    var $jcw0u;
    var $koeKL;
    const $0cab5e96cb0d59c5$var$PROJECT_LIST_RIGHT_PANEL_STATE = 'projectListRightPanelState';
    const $0cab5e96cb0d59c5$var$relayStoreUpdater = (store, userPreferencesRefId, state)=>{
        if (typeof userPreferencesRefId === 'string') {
            const record = store.get(userPreferencesRefId);
            record?.setValue(state, $0cab5e96cb0d59c5$var$PROJECT_LIST_RIGHT_PANEL_STATE);
        }
    };
    const $0cab5e96cb0d59c5$export$f7872413c3847449 = (userPreferencesRefId)=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const [commit] = (0, $8Uumt.useMutation)((0, (0, parcelRequire("5pqbm")).default));
        const setRightPanelState = (0, $5uXOq.useCallback)((state)=>{
            commit({
                variables: {
                    cloudId: cloudId,
                    state: state
                },
                optimisticUpdater: (store)=>$0cab5e96cb0d59c5$var$relayStoreUpdater(store, userPreferencesRefId, state),
                updater: (store, { jira: { userPreferences: { setProjectListRightPanelState: { projectListRightPanelState: projectListRightPanelState } } } })=>$0cab5e96cb0d59c5$var$relayStoreUpdater(store, userPreferencesRefId, projectListRightPanelState ?? state),
                onCompleted: ({ jira: { userPreferences: { setProjectListRightPanelState: { success: success, errors: errors } } } })=>{
                    const error = (errors ?? [])[0];
                    if (!success || error) {
                        const errorMessage = `error on set project list right panel state: ${error.message}`;
                        const statusCode = error.extensions?.statusCode;
                        (0, (0, parcelRequire("jcw0u")).default)({
                            error: new Error(`status code: ${statusCode ?? 'unknown'}, ${errorMessage}`),
                            meta: {
                                id: 'jiraSetProjectListRightPanelStateError',
                                packageName: 'jiraGrowthRecommendationsInProjectList'
                            }
                        });
                    }
                },
                onError: (error)=>{
                    (0, (0, parcelRequire("jcw0u")).default)({
                        error: new Error(`jiraSetProjectListRightPanelStateMutationError with ${error.message}`),
                        meta: {
                            id: 'jiraSetProjectListRightPanelStateMutationError',
                            packageName: 'jiraGrowthRecommendationsInProjectList'
                        }
                    });
                }
            });
        }, [
            cloudId,
            commit,
            userPreferencesRefId
        ]);
        return setRightPanelState;
    };
});
parcelRegister("5pqbm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$faa907682b1711b4$export$2e2bcd8739ae039);
    const $faa907682b1711b4$var$node = function() {
        var v0 = [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cloudId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "state"
            }
        ], v1 = [
            {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
            }
        ], v2 = [
            {
                "kind": "Variable",
                "name": "state",
                "variableName": "state"
            }
        ], v3 = {
            "kind": "ScalarField",
            "name": "success"
        }, v4 = {
            "kind": "ScalarField",
            "name": "statusCode"
        }, v5 = {
            "kind": "ScalarField",
            "name": "message"
        }, v6 = {
            "kind": "ScalarField",
            "name": "projectListRightPanelState"
        };
        return {
            "fragment": {
                "argumentDefinitions": v0,
                "kind": "Fragment",
                "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
                "selections": [
                    {
                        "concreteType": "JiraMutation",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "concreteType": "JiraUserPreferencesMutation",
                                "kind": "LinkedField",
                                "name": "userPreferences",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v2,
                                        "concreteType": "JiraProjectListRightPanelStateMutationPayload",
                                        "kind": "LinkedField",
                                        "name": "setProjectListRightPanelState",
                                        "plural": false,
                                        "selections": [
                                            v3,
                                            {
                                                "concreteType": "MutationError",
                                                "kind": "LinkedField",
                                                "name": "errors",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "extensions",
                                                        "plural": false,
                                                        "selections": [
                                                            v4
                                                        ]
                                                    },
                                                    v5
                                                ]
                                            },
                                            v6
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "type": "Mutation"
            },
            "kind": "Request",
            "operation": {
                "argumentDefinitions": v0,
                "kind": "Operation",
                "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
                "selections": [
                    {
                        "concreteType": "JiraMutation",
                        "kind": "LinkedField",
                        "name": "jira",
                        "plural": false,
                        "selections": [
                            {
                                "args": v1,
                                "concreteType": "JiraUserPreferencesMutation",
                                "kind": "LinkedField",
                                "name": "userPreferences",
                                "plural": false,
                                "selections": [
                                    {
                                        "args": v2,
                                        "concreteType": "JiraProjectListRightPanelStateMutationPayload",
                                        "kind": "LinkedField",
                                        "name": "setProjectListRightPanelState",
                                        "plural": false,
                                        "selections": [
                                            v3,
                                            {
                                                "concreteType": "MutationError",
                                                "kind": "LinkedField",
                                                "name": "errors",
                                                "plural": true,
                                                "selections": [
                                                    {
                                                        "kind": "LinkedField",
                                                        "name": "extensions",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "kind": "ScalarField",
                                                                "name": "__typename"
                                                            },
                                                            v4,
                                                            {
                                                                "kind": "InlineFragment",
                                                                "selections": [
                                                                    {
                                                                        "kind": "ScalarField",
                                                                        "name": "id"
                                                                    }
                                                                ],
                                                                "type": "BulkMutationErrorExtension"
                                                            }
                                                        ]
                                                    },
                                                    v5
                                                ]
                                            },
                                            v6
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "params": {
                "id": "c9fc45f1ccb390d27ba078874be435f11bb5799b236a50a6fc32b19b5b705f8b",
                "metadata": {},
                "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
                "operationKind": "mutation",
                "text": null
            }
        };
    }();
    $faa907682b1711b4$var$node.hash = "1cbcdd010c06eb6470327729729fba37";
    var $faa907682b1711b4$export$2e2bcd8739ae039 = $faa907682b1711b4$var$node;
});
parcelRegister("hIu9K", function(module, exports) {
    $parcel$export(module.exports, "TemplateCard", ()=>$3dc6c3e5c08b1b21$export$ba340e850b866867);
    var $7QrQO;
    var $5uXOq = parcelRequire("5uXOq");
    var $941eD = parcelRequire("941eD");
    var $8Uumt = parcelRequire("8Uumt");
    var $lj8SI;
    var $dC5iT;
    var $f5TWP;
    var $5ILhZ;
    var $luBm6;
    var $8CUq3;
    var $58GFP;
    var $47gW9;
    var $ivDCs;
    var $4mO32;
    var $6s1PB;
    var $4R6GH;
    var $koVbs;
    var $8zOmE;
    var $2NMNM;
    var $bKCrm;
    var $duTbU;
    var $2tT7R;
    var $cmVQ7;
    var $7Hq8b;
    var $cFkGL;
    var $kuaNg;
    var $6RphN;
    var $eqjno;
    const $3dc6c3e5c08b1b21$export$ba340e850b866867 = ({ dataRef: dataRef, recommendedProductIds: recommendedProductIds, setTemplatePreviewKey: setTemplatePreviewKey })=>{
        const environment = (0, (0, parcelRequire("2tT7R")).useEnvironment)();
        const { fireInteractedUIEvent: fireInteractedUIEvent } = (0, (0, parcelRequire("cmVQ7")).useFireCrossFlowAnalyticsEvents)();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const crossFlow = (0, $941eD.useCrossFlow)();
        const { methods: { open: openProjectCreate } } = (0, (0, parcelRequire("bKCrm")).useProjectCreateDrawer)();
        const cardEvent = createAnalyticsEvent({
            action: 'clicked',
            actionSubject: 'card'
        });
        const cardHoveredEvent = createAnalyticsEvent({
            action: 'hovered',
            actionSubject: 'card'
        });
        const template = (0, $8Uumt.useFragment)((0, (0, parcelRequire("7QrQO")).default), dataRef);
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const [expConfig] = (0, (0, parcelRequire("6s1PB")).UNSAFE_noExposureExp)('add_jpd_product_roadmap_template_reco_with_new_cb');
        const shouldUseNewRecommendations = expConfig.get('useNewRecommendations', false);
        const { title: title, shortDescription: shortDescription, key: key, isProductLicensed: isProductLicensed, isPremiumOnly: isPremiumOnly, productKey: productKey, templateType: templateType, recommendationSessionId: recommendationSessionId, canCreate: canCreate } = template;
        const { shouldPromptJsmTrial: shouldPromptJsmTrial } = (0, (0, parcelRequire("kuaNg")).checkShouldPromptJsmTrial)({
            templateKey: key,
            isProductLicensed: isProductLicensed,
            canCreate: canCreate
        });
        const [isJsmPremiumUpgradeModalOpen, setIsJsmPremiumUpgradeModalOpen] = (0, $5uXOq.useState)(false);
        const entryPoint = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("duTbU")).servicedeskStandardToPremiumModalEntryPoint), {});
        const triggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(entryPoint.entryPointActions);
        const extensions = (0, $5uXOq.useMemo)(()=>{
            const mergedExtensions = (0, (0, parcelRequire("eqjno")).buildCrossflowExtensions)({
                templateId: key,
                recommendationSessionId: recommendationSessionId,
                templateType: templateType
            });
            if (key === (0, (0, parcelRequire("58GFP")).IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY) && (0, (0, parcelRequire("4R6GH")).fg)('fix_advanced_itsm_recommendation_cross_flow')) Object.assign(mergedExtensions, {
                edition: 'premium'
            });
            return mergedExtensions;
        }, [
            key,
            recommendationSessionId,
            templateType
        ]);
        const onClick = (0, $5uXOq.useCallback)(()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(cardEvent, 'templateCard', {
                key: key,
                productKey: productKey,
                isProductLicensed: isProductLicensed,
                isPremiumOnly: isPremiumOnly,
                templateType: templateType,
                recommendationSessionId: recommendationSessionId,
                extensions: extensions
            });
            if (shouldPromptJsmTrial) {
                setIsJsmPremiumUpgradeModalOpen(true);
                return;
            }
            if (!isProductLicensed && crossFlow.isEnabled && (0, (0, parcelRequire("7Hq8b")).PROJECT_TYPE_TARGETS_MAP)[productKey] && (0, (0, parcelRequire("7Hq8b")).getProductTypeCcpReferenceIdMapForEnv)(environment)[productKey]) {
                fireInteractedUIEvent({
                    action: 'clicked',
                    actionSubject: 'card',
                    actionSubjectId: 'templateCard'
                }, {
                    recommendedProductIds: recommendedProductIds,
                    recommendationSession: {
                        id: recommendationSessionId,
                        type: 'appRec'
                    },
                    targetProductId: (0, (0, parcelRequire("7Hq8b")).getProductTypeCcpReferenceIdMapForEnv)(environment)[productKey],
                    source: 'projectsDirectorySidebarSection'
                });
                crossFlow.api.open({
                    journey: (0, $941eD.Journeys).GET_STARTED,
                    targetProduct: (0, (0, parcelRequire("7Hq8b")).PROJECT_TYPE_TARGETS_MAP)[productKey],
                    sourceComponent: 'projectsDirectorySidebarSection',
                    sourceContext: 'templateCard',
                    extensions: extensions
                });
                return;
            }
            openProjectCreate({
                selectedProjectType: productKey.toLowerCase(),
                showExperienceSelection: false,
                defaultSelectedTemplateKey: key,
                source: (0, (0, parcelRequire("7Hq8b")).PROJECT_CREATE_SOURCE),
                ...shouldUseNewRecommendations && {
                    recommendationSessionId: recommendationSessionId
                }
            });
        }, [
            cardEvent,
            key,
            productKey,
            isProductLicensed,
            isPremiumOnly,
            shouldUseNewRecommendations,
            templateType,
            recommendationSessionId,
            extensions,
            crossFlow,
            environment,
            openProjectCreate,
            fireInteractedUIEvent,
            shouldPromptJsmTrial,
            recommendedProductIds
        ]);
        const onPreviewDismiss = ()=>{
            setTemplatePreviewKey((current)=>{
                if (current === key) return null;
                return current;
            });
        };
        const onPreviewStart = ()=>{
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(cardHoveredEvent, 'templateCard', {
                key: key,
                productKey: productKey,
                isProductLicensed: isProductLicensed,
                templateType: templateType,
                isPremiumOnly: isPremiumOnly
            });
            setTemplatePreviewKey(key);
        };
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $3dc6c3e5c08b1b21$var$PaddedCardStyles,
            onMouseEnter: onPreviewStart,
            onMouseLeave: onPreviewDismiss
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5ILhZ")).default), {
            padding: "space.200",
            xcss: $3dc6c3e5c08b1b21$var$CardStyles,
            onFocus: onPreviewStart,
            onBlur: onPreviewDismiss,
            onClick: onClick,
            tabIndex: 0,
            ...shouldPromptJsmTrial && {
                ref: triggerRef
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("f5TWP")).default), {
            space: "space.150",
            alignBlock: "center"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6RphN")).default), {
            keyRef: template
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            as: "div",
            space: "space.050"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $3dc6c3e5c08b1b21$var$TitleStyles
        }, title, ' ', !isProductLicensed && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
            appearance: "new"
        }, formatMessage((0, (0, parcelRequire("cFkGL")).messages).tryLozenge)), isProductLicensed && isPremiumOnly && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lj8SI")).default), {
            appearance: "new"
        }, formatMessage((0, (0, parcelRequire("cFkGL")).messages).premiumLozenge))), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "p",
            xcss: $3dc6c3e5c08b1b21$var$DescriptionStyles
        }, shortDescription))))), isJsmPremiumUpgradeModalOpen && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            entryPointReferenceSubject: entryPoint.entryPointReferenceSubject,
            id: "standard-to-premium-modal",
            packageName: "jira-cmdb-upsell-page",
            teamName: "ITOps Mithril",
            entryPointActions: entryPoint.entryPointActions,
            runtimeProps: {
                onClose: ()=>{
                    setIsJsmPremiumUpgradeModalOpen(false);
                },
                origin: 'templateCard'
            }
        }));
    };
    const $3dc6c3e5c08b1b21$var$PaddedCardStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        backgroundColor: 'color.background.neutral.subtle',
        paddingBlock: 'space.100'
    });
    const $3dc6c3e5c08b1b21$var$CardStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '100%',
        textAlign: 'left',
        borderRadius: 'border.radius',
        boxShadow: 'elevation.shadow.raised',
        backgroundColor: 'elevation.surface.raised',
        ':hover': {
            background: 'elevation.surface.overlay',
            boxShadow: 'elevation.shadow.overlay'
        },
        ':focus': {
            background: 'elevation.surface.overlay',
            boxShadow: 'elevation.shadow.overlay'
        }
    });
    const $3dc6c3e5c08b1b21$var$TitleStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        color: 'color.text'
    });
    const $3dc6c3e5c08b1b21$var$DescriptionStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        margin: '0',
        color: 'color.text.subtlest',
        font: "var(--ds-font-body-UNSAFE_small, normal 400 12px/16px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)"
    });
});
parcelRegister("7QrQO", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$98a991d56d6cbcec$export$2e2bcd8739ae039);
    const $98a991d56d6cbcec$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "card_growthRecommendationsInProjectList_TemplateCard",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "title"
                },
                "action": "THROW",
                "path": "title"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "shortDescription"
                },
                "action": "THROW",
                "path": "shortDescription"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "key"
                },
                "action": "THROW",
                "path": "key"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "isProductLicensed"
                },
                "action": "THROW",
                "path": "isProductLicensed"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "isPremiumOnly"
                },
                "action": "THROW",
                "path": "isPremiumOnly"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "canCreate"
                },
                "action": "THROW",
                "path": "canCreate"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "productKey"
                },
                "action": "THROW",
                "path": "productKey"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "recommendationSessionId"
                },
                "action": "THROW",
                "path": "recommendationSessionId"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "templateType"
                },
                "action": "THROW",
                "path": "templateType"
            },
            {
                "kind": "FragmentSpread",
                "name": "icon_growthRecommendationsInProjectList_CardIcon"
            }
        ],
        "type": "JiraProjectListViewTemplateItem"
    };
    $98a991d56d6cbcec$var$node.hash = "4cc6cf43fdee3d68db9ae394dbfb1127";
    var $98a991d56d6cbcec$export$2e2bcd8739ae039 = $98a991d56d6cbcec$var$node;
});
parcelRegister("duTbU", function(module, exports) {
    $parcel$export(module.exports, "servicedeskStandardToPremiumModalEntryPoint", ()=>$7e434ef2c7309233$export$d21d0874477624e6);
    var $eufEc;
    var $fEDBv;
    const $7e434ef2c7309233$export$d21d0874477624e6 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("42Tyo")), {
            moduleId: "8d5c4464"
        }),
        getPreloadProps: ()=>({})
    });
});
parcelRegister("42Tyo", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("icbXl")
    ]).then(()=>parcelRequire('997lH'));
});
parcelRegister("kuaNg", function(module, exports) {
    $parcel$export(module.exports, "checkShouldPromptJsmTrial", ()=>$b5c17f030d74e6f2$export$aa1475d77bc9ab5f);
    var $58GFP;
    var $4R6GH;
    const $b5c17f030d74e6f2$export$aa1475d77bc9ab5f = ({ templateKey: templateKey, isProductLicensed: isProductLicensed, canCreate: canCreate })=>{
        return {
            shouldPromptJsmTrial: templateKey === (0, (0, parcelRequire("58GFP")).IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY) && isProductLicensed && !canCreate && (0, (0, parcelRequire("4R6GH")).fg)('jsm_it_service_management_advanced_template')
        };
    };
});
parcelRegister("6RphN", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4e323cd706cb4046$export$2e2bcd8739ae039);
    var $jL1KA;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $5qRwM;
    const $4e323cd706cb4046$export$f4619d70a16e5ea0 = ({ keyRef: keyRef })=>{
        const { iconUrl: iconUrl, iconDarkUrl: iconDarkUrl } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("jL1KA")).default), keyRef);
        if (!iconUrl || !iconDarkUrl) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement($4e323cd706cb4046$var$IconImage, {
            alt: "",
            src: iconUrl,
            srcDark: iconDarkUrl
        });
    };
    const $4e323cd706cb4046$var$IconImage = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("5qRwM")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb1ylp _4t3i1ylp _2rko1l7b",
                __cmplp.className
            ])
        });
    });
    var $4e323cd706cb4046$export$2e2bcd8739ae039 = $4e323cd706cb4046$export$f4619d70a16e5ea0;
});
parcelRegister("jL1KA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bdbbb75c4ffb1f50$export$2e2bcd8739ae039);
    const $bdbbb75c4ffb1f50$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "icon_growthRecommendationsInProjectList_CardIcon",
        "selections": [
            {
                "kind": "ScalarField",
                "name": "iconUrl"
            },
            {
                "kind": "ScalarField",
                "name": "iconDarkUrl"
            }
        ],
        "type": "JiraProjectListViewTemplateItem"
    };
    $bdbbb75c4ffb1f50$var$node.hash = "7e2f6da3f7159903d326f431a5c01c87";
    var $bdbbb75c4ffb1f50$export$2e2bcd8739ae039 = $bdbbb75c4ffb1f50$var$node;
});
parcelRegister("eqjno", function(module, exports) {
    $parcel$export(module.exports, "buildCrossflowExtensions", ()=>$21faa994fb2c234a$export$11382497cf741299);
    var $jFnH8 = parcelRequire("jFnH8");
    const $21faa994fb2c234a$export$11382497cf741299 = (args)=>{
        const { templateId: templateId, recommendationSessionId: recommendationSessionId, templateType: templateType } = args;
        const provisioningExtensions = (0, $jFnH8.createProvisioningPageExtensions)({
            actions: {
                primary: {
                    behaviour: (0, $jFnH8.Behaviours).LAND,
                    prompt: (0, $jFnH8.Prompts).NEXT,
                    options: {
                        targetTab: (0, $jFnH8.TargetTabs).SAME,
                        urlParams: {
                            templateId: templateType
                        }
                    }
                }
            }
        });
        const recoExtensions = (0, $jFnH8.createRecommendationContextExtensions)({
            recommendationSessionId: recommendationSessionId,
            entityId: templateId
        });
        const mergedExtensions = {};
        Object.assign(mergedExtensions, provisioningExtensions, recoExtensions);
        return mergedExtensions;
    };
});
parcelRegister("aV0Up", function(module, exports) {
    $parcel$export(module.exports, "TemplatePreview", ()=>$64357d8d5f727d82$export$48a171bbad47b95a);
    var $hh9K8;
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $8Uumt = parcelRequire("8Uumt");
    var $5qRwM;
    var $dC5iT;
    var $luBm6;
    var $8CUq3;
    var $iqLcb;
    var $7Hq8b;
    const $64357d8d5f727d82$export$48a171bbad47b95a = ({ update: update, previewRef: previewRef })=>{
        const { title: title, description: description, key: key, previewUrl: previewUrl, previewDarkUrl: previewDarkUrl } = (0, $8Uumt.useFragment)((0, (0, parcelRequire("hh9K8")).default), previewRef);
        (0, $5uXOq.useEffect)(()=>{
            if (update) update();
        }, [
            update
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: [
                $64357d8d5f727d82$var$TemplatePreviewWrapperStyles
            ]
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($64357d8d5f727d82$var$TemplateAreaContainer, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            xcss: $64357d8d5f727d82$var$PreviewWrapperStyles,
            space: "space.500"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("luBm6")).default), {
            space: "space.100",
            xcss: $64357d8d5f727d82$var$TextContainerStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $64357d8d5f727d82$var$TitleStyles
        }, title), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "p",
            xcss: $64357d8d5f727d82$var$DescriptionStyles
        }, description)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            as: "span",
            xcss: $64357d8d5f727d82$var$ImageWrapperStyles
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($64357d8d5f727d82$var$PreviewImage, {
            src: previewUrl,
            key: key,
            alt: "",
            srcDark: previewDarkUrl
        })))));
    };
    const $64357d8d5f727d82$var$TemplatePreviewWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: `calc(100vw - ${(0, (0, parcelRequire("7Hq8b")).SIDEBAR_WIDTH)}px)`,
        height: `calc(100vh - ${(0, (0, parcelRequire("iqLcb")).UNSAFE_DO_NOT_USE_TOP_NAVIGATION_HEIGHT)} - ${(0, (0, parcelRequire("iqLcb")).UNSAFE_DO_NOT_USE_BANNER_HEIGHT)} - 4px)`,
        backgroundColor: 'elevation.surface',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        ':empty': {
            display: 'none'
        }
    });
    const $64357d8d5f727d82$var$TemplateAreaContainer = (0, $5uXOq.forwardRef)(({ as: C = "span", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_1bsb12it _1tku1ssb",
                __cmplp.className
            ])
        });
    });
    const $64357d8d5f727d82$var$ImageWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        aspectRatio: '100/62'
    });
    const $64357d8d5f727d82$var$TextContainerStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        color: 'color.text'
    });
    const $64357d8d5f727d82$var$TitleStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-heading-xlarge, normal 600 29px/32px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        fontWeight: "var(--ds-font-weight-regular, 400)"
    });
    const $64357d8d5f727d82$var$DescriptionStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        font: "var(--ds-font-body, normal 400 14px/20px ui-sans-serif, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Ubuntu, system-ui, \"Helvetica Neue\", sans-serif)",
        margin: '0'
    });
    const $64357d8d5f727d82$var$PreviewWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        backgroundColor: 'elevation.surface'
    });
    const $64357d8d5f727d82$var$PreviewImage = (0, $5uXOq.forwardRef)(({ as: C = (0, (0, parcelRequire("5qRwM")).default), style: __cmpls, ...__cmplp }, __cmplr)=>{
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_5ral1f51 _rsufjp4b _1bsb1osq",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("hh9K8", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a3c0fc33e56a3a44$export$2e2bcd8739ae039);
    const $a3c0fc33e56a3a44$var$node = {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "name": "preview_growthRecommendationsInProjectList_TemplatePreview",
        "selections": [
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "title"
                },
                "action": "THROW",
                "path": "title"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "description"
                },
                "action": "THROW",
                "path": "description"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "key"
                },
                "action": "THROW",
                "path": "key"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "previewUrl"
                },
                "action": "THROW",
                "path": "previewUrl"
            },
            {
                "kind": "RequiredField",
                "field": {
                    "kind": "ScalarField",
                    "name": "previewDarkUrl"
                },
                "action": "THROW",
                "path": "previewDarkUrl"
            }
        ],
        "type": "JiraProjectListViewTemplateItem"
    };
    $a3c0fc33e56a3a44$var$node.hash = "f298dd557d1046875fbc288cf964ef36";
    var $a3c0fc33e56a3a44$export$2e2bcd8739ae039 = $a3c0fc33e56a3a44$var$node;
});
parcelRegister("8YXli", function(module, exports) {
    $parcel$export(module.exports, "JiraProjectListSidePanelSkipLink", ()=>$3fe75132cf7ef1d3$export$32072395400d2f38);
    var $koVbs;
    var $iqLcb;
    var $kVOqM;
    const $3fe75132cf7ef1d3$export$32072395400d2f38 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        (0, (0, parcelRequire("iqLcb")).useCustomSkipLink)('jira-project-list-side-panel', formatMessage((0, (0, parcelRequire("kVOqM")).default).skipToRightSidePanel), 4);
        return null;
    };
});
parcelRegister("kVOqM", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5a7d064e2be46b16$export$2e2bcd8739ae039);
    var $7VHMR;
    var $5a7d064e2be46b16$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        skipToRightSidePanel: {
            "id": "growth-recommendations-in-project-list.skip-link.skip-to-right-side-panel",
            "defaultMessage": "Skip to Side Panel"
        }
    });
});
